import { SpecialHandlingIndMappingConstant, specialHandlingIndMappingRequiredFieldList } from "presentation/constant/SpecialHandlingIndMapping/SpecialHandlingIndMappingConstant";
import { useSpecialHandlingIndMappingVM } from "presentation/hook/SpecialHandlingIndMapping/useSpecialHandlingIndMappingVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useSpecialHandlingIndMappingTracked } from "presentation/store/SpecialHandlingIndMapping/SpecialHandlingIndMappingProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { useCallback, useMemo, useState } from "react";
import { FieldType, HPHBreadcrumb, HPHCheckbox, IconButton, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { Breadcrumb, SidebarCaption, Sidebarheader, SidebarTitle, StyledAction, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const SpecialHandlingIndMappingEditPanel = () => {
    const SPECIAL_HANDLING_IND_MAPPING_CONSTANT = SpecialHandlingIndMappingConstant.Table;
    const [isLoading, setIsLoading] = useState(false);
    const [specialHandlingIndMappingState] = useSpecialHandlingIndMappingTracked();
    const specialHandlingIndMappingVM = useSpecialHandlingIndMappingVM();
    const { currentEditRow, isAdd, isEdit } = specialHandlingIndMappingState;
    const messageBarVM = useMessageBarVM();


    const handleSave = useCallback(async () => {
        if (currentEditRow.specialHandlingCodeList && currentEditRow.specialHandlingCodeList.length === 0) {
            messageBarVM.showError("Special Handling Code is mandatory.");
            return;
        }
        if (!currentEditRow.specialHandlingInd) {
            messageBarVM.showError("Special Handling Ind is mandatory.");
            return;
        }
        if (!currentEditRow.activeInd) {
            messageBarVM.showError("Active Ind is mandatory.");
            return;
        }
        setIsLoading(true);
        const data = await specialHandlingIndMappingVM.onSave(currentEditRow);
        if (data && data.success) {
            messageBarVM.showSuccess("Save Successfully.");
            specialHandlingIndMappingVM.searchAllspecialHandlingIndMappingList().then((data) => {
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
            });
        } else {
            setIsLoading(false)
            messageBarVM.showError(data.data);
            return;
        }
    }, [specialHandlingIndMappingVM, currentEditRow, messageBarVM]);

    const handClose = useCallback(() => {
        specialHandlingIndMappingVM.onCloseEidtPanel();
    }, [specialHandlingIndMappingVM]);


    const memoSpecialHandlingCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "450px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow.specialHandlingCodeList?.join('&')}
                fieldValue={currentEditRow?.specialHandlingCodeList ?? []}
                fieldLabel={SPECIAL_HANDLING_IND_MAPPING_CONSTANT.SPECIAL_HANDLING_CODE}
                isSaveClicked={false}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'specialHandlingCodeList'}
                maxLength={60}
                isMultipleDropDown={true}
                options={specialHandlingIndMappingState.dynamicOptions.specialHandlingDropdownOptions}
                requiredFieldList={specialHandlingIndMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    specialHandlingIndMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue, fFullValue)} />
        </div>
        , [SPECIAL_HANDLING_IND_MAPPING_CONSTANT.SPECIAL_HANDLING_CODE, currentEditRow.specialHandlingCodeList, isAdd, isEdit, specialHandlingIndMappingState.dynamicOptions.specialHandlingDropdownOptions, specialHandlingIndMappingVM])


    const memoSpecialHandlingCodeInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "450px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={{}}
                isReadOnly={!(isAdd || isEdit)}
                isShowOptional={true}
                readOnlyValue={currentEditRow?.specialHandlingInd || ''}
                fieldValue={currentEditRow?.specialHandlingInd}
                fieldLabel={SPECIAL_HANDLING_IND_MAPPING_CONSTANT.SPECIAL_HANDLING_IND}
                isSaveClicked={false}
                fieldType={FieldType.TEXT}
                fieldKey={'specialHandlingInd'}
                maxLength={5}
                requiredFieldList={specialHandlingIndMappingRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    specialHandlingIndMappingVM.onHeaderFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [SPECIAL_HANDLING_IND_MAPPING_CONSTANT.SPECIAL_HANDLING_IND, currentEditRow?.specialHandlingInd, isAdd, isEdit, specialHandlingIndMappingVM])


    const memoActiveInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "450px", marginBottom: "24px" }}>
            <NbisCommonField
                fieldKey={"activeInd"}
                requiredFieldList={specialHandlingIndMappingRequiredFieldList}
                fieldLabel={SPECIAL_HANDLING_IND_MAPPING_CONSTANT.ACTIVE_IND}
                fieldType={FieldType.TEXT}
                isReadOnly={true}
                onFieldChange={() => { }} />
            <HPHCheckbox
                label={""}
                checked={currentEditRow?.activeInd === 'Y'}
                disabled={!(isAdd || isEdit)}
                onChange={(e) => specialHandlingIndMappingVM.onCheckboxChange(e.checked, "activeInd")}
            />
        </div>
        , [SPECIAL_HANDLING_IND_MAPPING_CONSTANT.ACTIVE_IND, currentEditRow?.activeInd, isAdd, isEdit, specialHandlingIndMappingVM])

    const handleEdit = useCallback(() => {
        specialHandlingIndMappingVM.onEditClick();
    }, [specialHandlingIndMappingVM]);

    const handleReset = useCallback(() => {
        specialHandlingIndMappingVM.onResetClick(isAdd);
    }, [specialHandlingIndMappingVM, isAdd]);

    return <>
        <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'>
            <Sidebarheader style={{ width: '100%' }}>
                {isLoading && <Loader Indicator="Spinner" size="Medium" />}
                <Breadcrumb>
                    <HPHBreadcrumb breadcrumbData={[{ title: SpecialHandlingIndMappingConstant.Title.TITLE },
                    { title: SpecialHandlingIndMappingConstant.Title.TITLE }]} onCurrentClick={() => { }}>
                    </HPHBreadcrumb>
                </Breadcrumb>
                <StyledAction>
                    <>
                        {!(isAdd || isEdit) && <IconButton fileName='Icon-pen' size='medium' disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Edit'} onClick={handleEdit} />}
                        {(isAdd || isEdit) && <IconButton fileName="Icon-reset" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Reset'} onClick={() => handleReset()} />}

                        <IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement='bottom' toolTipText={'Close'} onClick={() => handClose()} />
                        {(isAdd || isEdit) && <IconButton fileName="Icon-tick" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSave()} />}
                    </>
                </StyledAction>
            </Sidebarheader>

            <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <div className={'im-flex-row'}>
                            <div className={'flex-row-item-stretch'}>
                                <SidebarTitle>
                                    {isAdd && "New SP Handling Indicator Setting"}
                                    {!isAdd && (currentEditRow.priority + "/" + currentEditRow.specialHandlingCodeList?.join('&'))}
                                </SidebarTitle>
                                {<SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                            </div>
                        </div>

                        <CriteriaItemContainer>
                            {memoSpecialHandlingCode}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoSpecialHandlingCodeInd}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoActiveInd}
                        </CriteriaItemContainer>

                    </div>
                </div>
            </div>
        </StyledSidebar>
    </>
}

export default SpecialHandlingIndMappingEditPanel;
