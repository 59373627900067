import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { SpecialHandlingIndMappingConstant } from "./SpecialHandlingIndMappingConstant";

const SPECIAL_HANDLING_CODE_IND_MAPPING_CONSTANT = SpecialHandlingIndMappingConstant.Table;
let dateFieldList:string[] = [];
let dateTimeFieldList:string[] = [];
export const INITIAL_SPECIAL_HANDLING_IND_MAPPING_COL_DEF: any[] = [

    {
        headerName: SPECIAL_HANDLING_CODE_IND_MAPPING_CONSTANT.PRIORITY,
        field: "priority",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 150,
        // pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        },
        {
            headerName: SPECIAL_HANDLING_CODE_IND_MAPPING_CONSTANT.SPECIAL_HANDLING_CODE,
            field: "specialHandlingCode",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 230,
            },
            {
            headerName: SPECIAL_HANDLING_CODE_IND_MAPPING_CONSTANT.SPECIAL_HANDLING_IND,
            field: "specialHandlingInd",
            enableRowGroup: false,
            enablePivot: true,
            enableValue: false,
            rowGroup: false,
            filter: false,
            width: 230,
            },
        {
        headerName: SPECIAL_HANDLING_CODE_IND_MAPPING_CONSTANT.ACTIVE_IND,
        field: "activeInd",
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 130,
        },
        
].map((col, index) => {
    const cellRenderers:{[key:string]:((params:{[key:string]:string}, fieldName:string) => {})} = {};

    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
});

export const transferRowData = (data:any[]) => {
    const externalFnctions:{[key:string]:((fieldName:string, row:any) => {})} = {};

    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}