import { SpecialHandlingIndMappingConstant } from "presentation/constant/SpecialHandlingIndMapping/SpecialHandlingIndMappingConstant";
import { useSpecialHandlingIndMappingVM } from "presentation/hook/SpecialHandlingIndMapping/useSpecialHandlingIndMappingVM";
import { HeaderTitle } from "presentation/view/components/HeaderWithBackButton/HeaderTitle";
import { memo, useCallback } from "react";
import { IconButton } from "veronica-ui-component/dist/component/core";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const SpecialHandlingIndMappingTitleBar:React.FC = () => {
    const SPECIAL_HANDLING_IND_MAPPING_CONSTANT= SpecialHandlingIndMappingConstant.Title;
    const specialHandlingIndMappingVM = useSpecialHandlingIndMappingVM();
    // const [specialHandlingIndMappingState] = useSpecialHandlingIndMappingTracked();
    // const [isLoading, setIsLoading] = useState(false);
    // const {updatedRows} = specialHandlingIndMappingState;
    // const messageBarVM = useMessageBarVM();  
    
    const handleAdd = useCallback(() => {
        specialHandlingIndMappingVM.onAddClick();
    }, [specialHandlingIndMappingVM]);

    // const handleApply = useCallback(async () => {
    //     if(_.isEmpty(updatedRows)) return;
    //     setIsLoading(true);
    //     const res = await specialHandlingIndMappingVM.onApply(updatedRows);
    //     setIsLoading(false);
    //     if(!!!res || !res.success){
    //         messageBarVM.showWarining(res.data);
    //     }else{
    //         messageBarVM.showSuccess('Apply Successful.');
    //         await specialHandlingIndMappingVM.searchAllspecialHandlingIndMappingList();
    //     }
    //     setIsLoading(false);
    // }, [specialHandlingIndMappingVM, messageBarVM, updatedRows]);

    
    // const handleRefresh = useCallback(async () => {
    //     setIsLoading(true);
    //     specialHandlingIndMappingVM.searchAllspecialHandlingIndMappingList().then((data) => {            
    //         setIsLoading(false)
    //     }).catch(error => {            
    //         setIsLoading(false)
    //     });
    // },[specialHandlingIndMappingVM]);

    // const isApplyDisable = () => {
    //     if(updatedRows.length !== 0) return false
    //     return true;
    // }

    return <Sidebarheader style={{width: '100%', display:"flex", alignItems:"center"}}>
        <HeaderTitle>{SPECIAL_HANDLING_IND_MAPPING_CONSTANT.TITLE}</HeaderTitle>
        {/* {(isLoading) && <Loader Indicator="Spinner" size="Medium" /> } */}
        <StyledAction className="tm-animated-wrapper">
            {/* <IconButton fileName='Icon-reload' disabled={false} size='medium' toolTipText={'Refresh'} onClick={handleRefresh}/>
            <div className="add-seperator"/> */}
            <IconButton fileName='Icon-add' disabled={false} size='medium' toolTipText={'Add'} toolTipArrow={false} onClick={handleAdd} />
            {/* <div className="add-seperator"/>
            <HPHButton label={WorkspaceConstant.Common.BUTTON_APPLY} size={"Small"} theme={"Primary"}  disabled={isApplyDisable()} onClick={handleApply} /> */}
        </StyledAction>
    </Sidebarheader>
}

export default memo(SpecialHandlingIndMappingTitleBar);